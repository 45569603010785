import { graphql } from "gatsby";
import React from "react";
import Markdown from "react-markdown";
import { siteMetadata } from "../../gatsby-config";
import { categoryParams } from "../../node-category";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import CategoryTree from "../components/category-tree/category-tree";
import IconsLineCat from "../components/icons-line-cat/icons-line-cat";
import Layout from "../components/layout";
import ProductList from "../components/product-list/product-list";
import SearchForm from "../components/search-form/search-form";

export const query = graphql`
  query CategoryQuery($slug: String!) {
    strapiCategory(slug: { eq: $slug }) {
      id
      description
      metatitle
      metadescription
      page_header
      page_side
      page_content
      slug
      strapiId
      strapiParent {
          id
        }
      name
      visuel {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 400, height: 400) 
          }
        }
      }
    }
    allStrapiProduct(
      filter: {
        variations: {elemMatch: {attributs_couleur: { Nom: {eq: $slug}}}}
        categories: {elemMatch: {id: {in: [12,16,8,9,27]}}}
        status: { eq: "published" }
      }
      sort: {
        position: ASC
      }
    ) {
      edges {
        node {
          slug
          status
          strapiId
          position
          title
          categories {
            name
            id
            slug
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 230
                  height: 280
                ) 
              }
            }
          }
          variations {
            attributs_couleur {
              Nom
            }
            Image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 230
                    height: 280
                  ) 
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CategoryColor = ({ data }) => {
  const category = data.strapiCategory;
  const color = category.slug;
  const products = data.allStrapiProduct.edges.map((e) => ({
    ...e.node,
    image: e.node.variations.find(v => v.attributs_couleur.Nom === color).Image,
    variations: e.node.variations.filter(v => v.attributs_couleur.Nom === color)
  }));
  const seo = {
    metaTitle: category.metatitle || category.name,
    metaDescription: category.metadescription || category.description,
    shareImage: category.visuel,
    article: false,
    canonical: siteMetadata.siteUrl + categoryParams.urlPrefix + category.slug,
    scripts: [
      {
        type: "application/ld+json",
        content: `{
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          "image": "${category.visuel}",
          "name": "${category.name}",
          "description": "${category.description}"
        }`
      },
      {
        type: "application/ld+json",
        content: `{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement":[
            {
              "@type": "ListItem",
              "position": 1,
              "item":{
                "@id": "https://latelierdutotebag.com",
                "name": "Accueil"
              }
            },{
              "@type": "ListItem",
              "position": 2,
              "item":{
                "@id": "https://latelierdutotebag.com/${category.slug}",
                "name": "${category.name}"
              }
            }
          ]
        }`
      }

    ]
  };

  return (
    <Layout seo={seo} showHero={false}>
      <div className="bg-gray-100">
        <div className="container py-4">
          <h1 className="text-center">{category.name}</h1>
          <div className="text-center my-3">
            <Markdown>{category.page_header}</Markdown>
            <IconsLineCat />
          </div>
        </div>
      </div>
      <div className="py-4">
        <div className="my-4">
          <Breadcrumbs category={category} />
        </div>
        <div className="flex flex-wrap ">
          <div className="w-full order-2 md:order-1 md:w-3/12 md:pr-4">
            <h3 className="text-base font-semibold uppercase pb-2 border-b-3 border-gray-200">
              Rechercher un produit
            </h3>
            <SearchForm />
            <h3 className="mt-8 text-base font-semibold uppercase pb-2 border-b-3 border-gray-200">
              Catégories
            </h3>
            <CategoryTree currentCat={category} shouldFindRoot={true} />

            <div className="cat-side">
              <Markdown>{category.page_side}</Markdown>
            </div>
          </div>
          <div className="w-full order-1 md:order-2 md:w-9/12">
            <span className="font-semibold text-gray-700">
              Découvrez tous les tote bags personnalisés, proposés et fabriqués
              par l’Atelier du Tote Bag.
            </span>
            <ProductList products={products} />
          </div>
        </div>
        <hr className="my-4" />
        <div className="cat-content">
          <Markdown>{category.page_content}</Markdown>
        </div>
      </div>
    </Layout>
  );
};

export default CategoryColor;
