import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { categoryParams } from "../../../node-category";

const CategoryTree = ({ currentCat, inputCat }) => {
  const data = useStaticQuery(query);
  let rootCat = inputCat || currentCat;
  const shouldFindRoot = inputCat === undefined
  let isColorCat = false
  if (shouldFindRoot) {
    while (rootCat && rootCat.strapiParent) {
      // color category is a child of the root category
      if (rootCat.strapiParent.id === 38) {
        isColorCat = true;
        rootCat = data.allStrapiCategory.nodes.find(n => n.strapiId === 12) // root category for tote bags
      }
      else
        rootCat = data.allStrapiCategory.nodes.find(n => n.strapiId === rootCat.strapiParent.id)
    }
  }
  const children = isColorCat
    ? data.allStrapiCategory.nodes.filter(n => n.strapiParent && n.strapiParent.id === 38)
    : data.allStrapiCategory.nodes.filter(n => n.strapiParent && n.strapiParent.id === rootCat.strapiId)

  return (
    <ul className="product-categories">
      <li >
        {currentCat.strapiId === rootCat.strapiId &&
          <div className={'underline uppercase my-2 text-sm text-blue font-semibold'}>{rootCat.name}</div>
        }
        {currentCat.strapiId !== rootCat.strapiId &&
          <Link to={categoryParams.urlPrefix + rootCat.slug}>{rootCat.name}</Link>
        }
        {children.length > 0 && (
          <div className="pl-4">
            {children.map((cat) =>
              <CategoryTree key={cat.strapiId} currentCat={currentCat} inputCat={cat}></CategoryTree>
            )}
          </div>
        )}
      </li>

    </ul>
  );
};

const query = graphql`
  query {
    allStrapiCategory(sort: {position: ASC})  {
      nodes {
        slug
        id
        position
        name
        strapiId
        strapiParent {
          id
        }
        strapiChildren {
          id
        }
        products {
          id
        }
      }
    }
  }
`;

export default CategoryTree;
