exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blue-slider-js": () => import("./../../../src/pages/blue-slider.js" /* webpackChunkName: "component---src-pages-blue-slider-js" */),
  "component---src-pages-categorie-produit-js": () => import("./../../../src/pages/categorie-produit.js" /* webpackChunkName: "component---src-pages-categorie-produit-js" */),
  "component---src-pages-contacter-l-atelier-du-tote-bag-js": () => import("./../../../src/pages/contacter-l-atelier-du-tote-bag.js" /* webpackChunkName: "component---src-pages-contacter-l-atelier-du-tote-bag-js" */),
  "component---src-pages-impression-broderie-js": () => import("./../../../src/pages/impression-broderie.js" /* webpackChunkName: "component---src-pages-impression-broderie-js" */),
  "component---src-pages-impression-numerique-js": () => import("./../../../src/pages/impression-numerique.js" /* webpackChunkName: "component---src-pages-impression-numerique-js" */),
  "component---src-pages-impression-serigraphie-js": () => import("./../../../src/pages/impression-serigraphie.js" /* webpackChunkName: "component---src-pages-impression-serigraphie-js" */),
  "component---src-pages-impression-transfert-js": () => import("./../../../src/pages/impression-transfert.js" /* webpackChunkName: "component---src-pages-impression-transfert-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-l-atelier-du-tote-bag-js": () => import("./../../../src/pages/l-atelier-du-tote-bag.js" /* webpackChunkName: "component---src-pages-l-atelier-du-tote-bag-js" */),
  "component---src-pages-remerciements-js": () => import("./../../../src/pages/remerciements.js" /* webpackChunkName: "component---src-pages-remerciements-js" */),
  "component---src-pages-sacs-en-tissu-personnalises-js": () => import("./../../../src/pages/sacs-en-tissu-personnalises.js" /* webpackChunkName: "component---src-pages-sacs-en-tissu-personnalises-js" */),
  "component---src-pages-tote-bag-sur-mesure-js": () => import("./../../../src/pages/tote-bag-sur-mesure.js" /* webpackChunkName: "component---src-pages-tote-bag-sur-mesure-js" */),
  "component---src-pages-tote-bags-inspirations-js": () => import("./../../../src/pages/tote-bags-inspirations.js" /* webpackChunkName: "component---src-pages-tote-bags-inspirations-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-category-color-js": () => import("./../../../src/templates/category-color.js" /* webpackChunkName: "component---src-templates-category-color-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-le-blog-js": () => import("./../../../src/templates/le-blog.js" /* webpackChunkName: "component---src-templates-le-blog-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

