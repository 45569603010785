exports.categoryParams = {
  urlPrefix: "/categorie-produit/",
};

exports.createCategories = async ({ graphql, actions }) => {
  const { createPage } = actions;
  const result = await graphql(
    `
      {
        categories: allStrapiCategory {
          edges {
            node {
              slug
              strapiParent {
                slug
              }
              strapiId
            }
          }
        }
      }
    `
  );

  if (result.errors) {
    throw result.errors;
  }

  const categories = result.data.categories.edges;
  const CategoryTemplate = require.resolve("./src/templates/category.js");
  const CategoryColorTemplate = require.resolve("./src/templates/category-color.js");

  categories.forEach((category, index) => {
    if (category.node.strapiParent && category.node.strapiParent.slug === "tote-bags-par-couleur")
      createPage({
        path: `${this.categoryParams.urlPrefix}${category.node.slug}`,
        component: CategoryColorTemplate,
        context: {
          ...category.node,
        },
      });
    else
      createPage({
        path: `${this.categoryParams.urlPrefix}${category.node.slug}`,
        component: CategoryTemplate,
        context: {
          ...category.node,
        },
      });
  });
};
